@import "~include-media/dist/_include-media.scss";

.container {
  position: relative;
  width: 100vw;
  height: 100%;
}

.imgContainer {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @include media("<=lphone") {
    width: unset;
    justify-content: flex-start;
    align-items: flex-end;
  }
  @include media("<tablet") {
    justify-content: flex-start;
  }
}

.self {
  user-select: none;
  width: 86%;
  object-fit: contain;
  transform: translateY(5%);

  @include media("<=lphone") {
    width: 110%;
    transform: translateX(15%);
  }

  @include media("<=tablet", ">lphone") {
    width: 110%;
  }
}
.homeBtn {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: pointer;
}

.infoContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 50vw;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  color: white;

  @include media("<=lphone") {
    width: unset;
    align-items: flex-start;
    justify-content: flex-end;
  }
}
.subParent {
  border-left: solid 1px rgba(128, 128, 128, 0.337);
  padding-left: 25px;
}

.leftParent {
  width: 72%;
  margin-left: auto;
  @include media("<=lphone") {
    margin-left: 20px;
    margin-bottom: 30px;
  }
}

.infoWrapper {
  // max-width: 65%;
  &:last-child {
    margin-top: 40px;
  }
}

.infoTitle {
  font-size: 1.6rem;
  font-weight: 700;

  @include media("<=lphone") {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  @include media("<=tablet", ">lphone") {
    font-size: 1.3rem;
    line-height: 2rem;
  }

  @include media("<=desktop", ">tablet") {
    font-size: 1.4rem;
    line-height: 2.3rem;
  }

  @include media(">=LGdesktop") {
    font-size: 3rem;
  }
}

.infoSubTitle {
  font-weight: 300;
  font-size: 0.8rem;
  opacity: 0.5;
  line-height: 1.4rem;
  margin-top: 10px;

  @include media("<=tablet") {
    font-size: 0.6rem;
    line-height: 1rem;
  }

  @include media("<=desktop", ">tablet") {
    font-size: 0.7rem;
    line-height: 1.2rem;
  }

  @include media(">=LGdesktop") {
    font-size: 1.6rem;
    line-height: 2.7rem;
    margin-top: 20px;
  }
}

.infoBlockWrapper {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto auto;
  margin-top: 18px;

  @include media("<=tablet") {
    margin-top: 0.6rem;
  }

  @include media("<=desktop", ">tablet") {
    margin-top: 0.6rem;
  }

  @include media(">=LGdesktop") {
    margin-top: 3rem;
  }
}
.infoBlockTitle {
  font-weight: 300;

  @include media("<=tablet") {
    font-size: 0.7rem;
    line-height: 1rem;
  }

  @include media("<=desktop", ">tablet") {
    font-size: 0.7rem;
    line-height: 1.2rem;
  }

  @include media(">=LGdesktop") {
    font-size: 1.6rem;
    line-height: 2.7rem;
  }
}
.infoBlockValue {
  font-weight: 200;
  font-size: 0.7rem;

  @include media("<=tablet") {
    font-size: 0.5rem;
    line-height: 1rem;
  }

  @include media("<=desktop", ">tablet") {
    font-size: 0.6rem;
    line-height: 1.2rem;
  }

  @include media(">=LGdesktop") {
    font-size: 1.5rem;
    line-height: 2.7rem;
  }
}
