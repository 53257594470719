.sectionParent {
  position: absolute;
  width: 100%;
  height: 101%;
  overflow: scroll;
  overflow-x: hidden;

  scroll-snap-type: y proximity;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0px;
    visibility: hidden;
    opacity: 0;
  }
}

section {
  scroll-snap-align: start;
  height: 100%;
  width: 100vw;
}

.sectionBlock {
  background: rgb(71, 71, 71);
  background: linear-gradient(45deg, rgba(71, 71, 71, 1) 0%, rgba(32, 32, 32, 1) 57%, rgba(20, 20, 20, 1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.sectionBlock2 {
  background: rgb(71, 71, 71);
  background: linear-gradient(230deg, #474747 0%, #202020 57%, #141414 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.sectionBlock3 {
  overflow: hidden;
}
