@import "~include-media/dist/_include-media.scss";

.textParent {
  height: fit-content;
  width: 100%;
  color: white;
  font-size: 1.8rem;
  line-height: 2.9rem;
  user-select: none;
  text-align: center;

  @include media("<=phone") {
  }
  @include media(">phone", "<=lphone") {
  }
  @include media("<lphone") {
    font-size: 1.2rem;
    line-height: 2rem;
    transform: translateY(-10%);
  }
  @include media("<tablet", ">=lphone") {
    font-size: 1.3rem;
    line-height: 2.2rem;
  }
  @include media("<desktop") {
    font-size: 1.3rem;
    line-height: 2.2rem;
  }
  @include media(">=desktop") {
    font-size: 2rem;
    line-height: 3rem;
  }
  @include media(">=llaptop") {
    font-size: 2.6rem;
    line-height: 4rem;
  }
  @include media(">=LGdesktop") {
    font-size: 3.5rem;
    line-height: 4.8rem;
    font-weight: 400;
  }
}

.highlightText {
  width: fit-content;
  color: #98ca0d;
  position: relative;
  margin-left: 10px;
  cursor: pointer;
}

.highlightText:hover > .popWrap {
  opacity: 0.5;
}

.highlight2 {
  color: #0fb4f2;
}

.popWrap {
  position: absolute;
  color: #ffffff;
  opacity: 0.15;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.textPop {
  background: #828282;
  border-radius: 12px;
  padding: 3px 20px;
  font-size: 1.4rem;
  width: max-content;

  @include media("<=phone") {
  }
  @include media("<lphone") {
    font-size: 0.9rem;
    border-radius: 8px;
    padding: 0px 9px;
  }
  @include media("<tablet", ">=lphone") {
    font-size: 1rem;
    border-radius: 10px;
    padding: 3px 12px;
  }
  @include media("<desktop") {
    font-size: 1rem;
    border-radius: 10px;
    padding: 3px 12px;
  }

  @include media(">=LGdesktop") {
    font-size: 2.1rem;
  }
}

.pop1 {
  top: -175%;
  left: 50%;
  transform: translateX(-10%);
  background-repeat: no-repeat;
  background-size: 32% 56%;
  background-position: -2% 85%;
  padding-left: 25%;
  padding-bottom: 10%;
  background-image: url("~assets/arrow_1.svg");

  @include media("<=phone") {
  }
  @include media(">phone", "<=lphone") {
  }
  @include media("<tablet") {
    left: 35%;
  }
}

.pop2 {
  top: 35%;
  left: -8%;
  transform: translateX(50%);
  background-repeat: no-repeat;
  background-size: 32% 56%;
  background-position: 2% 6%;
  padding-left: 91%;
  background-image: url("~assets/arrow_3.svg");

  @include media("<=phone") {
  }
  @include media(">phone", "<=lphone") {
  }
  @include media("<tablet") {
    top: 275%;
    left: -102%;
    background-repeat: no-repeat;
    background-size: 32% 56%;
    background-position: 2% 6%;
    transform: none;
    background-image: none;
    padding-top: 10%;
    padding-left: 8%;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: -12%;
      left: 0%;
      z-index: -1;
      background-repeat: no-repeat;
      background-image: url("~assets/arrow_3.svg");
      transform: rotate(90deg);
      background-size: 32% 56%;
    }
  }
  @include media(">=tablet", "<desktop") {
    top: 300%;
    left: -180%;
    padding-left: 58%;
    background-image: none;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: -12%;
      left: 0%;
      z-index: -1;
      background-repeat: no-repeat;
      background-image: url("~assets/arrow_3.svg");
      transform: rotate(90deg);
      background-size: 32% 56%;
    }
  }
  @include media(">=desktop", "<llaptop") {
    top: 135%;
    left: 146%;
    transform: translateX(-50%);
    background-repeat: no-repeat;
    background-size: 32% 56%;
    background-position: 6% 0%;
    padding-left: 100%;
    padding-top: 25%;
    background-image: url("~assets/arrow_4.svg");
  }
  @include media(">=llaptop") {
    background-position: 10% 6%;
    padding-left: 100%;
  }
  @include media(">=LGdesktop") {
    background-position: 10% 6%;
    padding-left: 100%;
  }
}

.pop3 {
  top: 135%;
  left: -58%;
  transform: translateX(-50%);
  background-repeat: no-repeat;
  background-size: 32% 56%;
  background-position: 94% 0%;
  padding-right: 98%;
  padding-top: 25%;
  background-image: url("~assets/arrow_2.svg");

  @include media("<lphone") {
    background-image: none;
    top: 215%;
    left: -10%;
    transform: translateX(-10%);
    padding-right: 65%;
    padding-top: 45%;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: -111%;
      left: 24%;
      z-index: -1;
      background-repeat: no-repeat;
      background-image: url("~assets/arrow_2.svg");
      transform: rotate(320deg);
      background-size: 32% 62%;
    }
  }
  @include media("<tablet", ">=lphone") {
    background-image: none;
    top: 215%;
    left: -6%;
    transform: translateX(-5%);
    padding-right: 65%;
    padding-top: 45%;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: -111%;
      left: 24%;
      z-index: -1;
      background-repeat: no-repeat;
      background-image: url("~assets/arrow_2.svg");
      transform: rotate(320deg);
      background-size: 32% 62%;
    }
  }
}

.pop4 {
  top: 110%;
  left: 50%;
  transform: translateX(-10%);
  background-repeat: no-repeat;
  background-size: 32% 56%;
  background-position: 11% 25%;
  padding-left: 80%;
  padding-top: 25%;
  background-image: url("~assets/arrow_4.svg");

  @include media("<lphone") {
    background-image: none;
    top: 50%;
    left: -80%;
    padding-left: 0;
    background-image: none;
    padding-top: 88%;
    transform: none;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 15%;
      left: 24%;
      z-index: -1;
      background-repeat: no-repeat;
      background-image: url("~assets/arrow_5.svg");
      transform: rotate(30deg);
      background-size: 51% 56%;
    }
  }
  @include media("<tablet", ">=lphone") {
    background-image: none;
    top: 50%;
    left: -46%;
    padding-left: 0;
    background-image: none;
    padding-top: 88%;
    transform: none;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 15%;
      left: 24%;
      z-index: -1;
      background-repeat: no-repeat;
      background-image: url("~assets/arrow_5.svg");
      transform: rotate(22deg);
      background-size: 51% 56%;
    }
  }
  @include media(">=tablet", "<desktop") {
    left: -54% !important;
  }
  @include media(">=tablet", "<llaptop") {
    top: 135%;
    left: -34%;
    transform: translateX(-50%);
    background-repeat: no-repeat;
    background-size: 32% 56%;
    background-position: 96% 0%;
    padding-right: 85%;
    padding-top: 25%;
    background-image: url("~assets/arrow_2.svg");
  }
  @include media(">=llaptop") {
  }
  @include media(">=LGdesktop") {
  }
}
