@import "~include-media/dist/_include-media.scss";

.container {
  background: rgb(71, 71, 71);
  background: linear-gradient(230deg, #474747 0%, #202020 57%, #141414 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  position: relative;
  width: 100vw;
  height: 100vh;

  @include media("<=lphone") {
    flex-direction: column;
    justify-content: start;
  }
}

.homeBtn {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  cursor: pointer;
}

.infoContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 45vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  color: white;

  @include media("<=lphone") {
    height: unset;
    width: unset;
    position: unset;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.subParent {
  border-left: solid 1px rgba(128, 128, 128, 0.337);
  padding-left: 25px;
}

.leftParent {
  width: 75%;
  margin-left: auto;

  @include media("<=lphone") {
    margin-left: 20px;
    margin-top: 20px;
  }
}

.infoWrapper {
  // max-width: 65%;
  // &:last-child {
  //   margin-top: 40px;
  // }
}

.infoTitle {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 5%;
  @include media("<=lphone") {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  @include media("<=tablet", ">lphone") {
    font-size: 1.3rem;
    line-height: 2rem;
  }

  @include media("<=desktop", ">tablet") {
    font-size: 1.4rem;
    line-height: 2.3rem;
  }

  @include media(">=LGdesktop") {
    font-size: 3rem;
  }
}

.infoSubTitle {
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 1.4rem;
  opacity: 0.6;
  margin-top: 10px;

  @include media("<=tablet") {
    font-size: 0.6rem;
    line-height: 1rem;
  }

  @include media("<=desktop", ">tablet") {
    font-size: 0.7rem;
    line-height: 1.2rem;
  }

  @include media(">=LGdesktop") {
    font-size: 1.6rem;
    line-height: 2.7rem;
    margin-top: 20px;
  }
}

.infoBlockWrapper {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto auto;
  margin-top: 18px;

  @include media("<=tablet") {
    margin-top: 0.6rem;
  }

  @include media("<=desktop", ">tablet") {
    margin-top: 0.6rem;
  }

  @include media(">=LGdesktop") {
    margin-top: 3rem;
  }
}
.infoBlockTitle {
  font-weight: 300;
  margin-bottom: 10px;

  @include media("<=tablet") {
    font-size: 0.7rem;
    line-height: 1rem;
  }

  @include media("<=desktop", ">tablet") {
    font-size: 0.7rem;
    line-height: 1.2rem;
  }

  @include media(">=LGdesktop") {
    font-size: 1.6rem;
    line-height: 2.7rem;
  }
}
.infoBlockValue {
  font-weight: 200;
  font-size: 0.7rem;

  @include media("<=tablet") {
    font-size: 0.5rem;
    line-height: 1rem;
  }

  @include media("<=desktop", ">tablet") {
    font-size: 0.6rem;
    line-height: 1.2rem;
  }

  @include media(">=LGdesktop") {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
}

.mobileParent {
  display: flex;
  width: 50vw;
  top: 0;
  right: 0;
  position: absolute;
  height: 100%;

  @include media("<=lphone") {
    height: unset;
    width: unset;
    position: unset;
  }
  @include media(">=LGdesktop") {
    justify-content: start;
    align-items: center;
    width: 46vw;
  }
}

.mobile {
  display: block;
  object-fit: contain;
  opacity: 0.4;
  cursor: pointer;
  transition: all 400ms ease;

  &:hover {
    opacity: 1 !important;
  }
}

.mobileParent:hover > .mobileCenterParent1 .mobile {
  opacity: 0.05;
}

.mobileParent:hover > .mobileCenterParent2 .mobile {
  opacity: 0.05;
}

.mobileParent:hover > .mobileCenterParent3 .mobile {
  opacity: 0.05;
}

.mobileCenterParent1 {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 30;

  @include media(">=LGdesktop") {
    width: 22%;
  }
}

.mobileCenterParent2 {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include media(">desktop") {
    justify-content: flex-start;
  }

  @include media(">=LGdesktop") {
    width: 22%;
  }
}

.mobileCenterParent2Btm {
  justify-content: flex-end !important;
}

// .mobileCenterParent2:has(> img) {
// }

.mobileCenterParent3 {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(18%);
  z-index: 20;
  justify-content: center;

  @include media(">desktop") {
    justify-content: flex-start;
  }

  @include media(">=LGdesktop") {
    width: 22%;
  }
}

.mobile1 {
  width: 90%;
  margin-right: 1%;
  z-index: 30;

  @include media(">=LGdesktop") {
    width: 100%;
  }

  &:hover {
    width: 110% !important;
  }
}
.mobile2 {
  width: 90%;
  margin-top: 5%;
  z-index: 20;

  @include media(">=LGdesktop") {
    width: 100%;
  }
  &:hover {
    width: 110% !important;
  }
}
.mobile3 {
  width: 90%;
  margin-top: 8%;
  z-index: 30;

  @include media(">=LGdesktop") {
    width: 100%;
  }

  &:hover {
    width: 110% !important;
    margin-top: -67%;
  }
}
.mobile4 {
  width: 90%;
  margin-right: 0%;

  @include media(">=LGdesktop") {
    width: 100%;
  }

  &:hover {
    width: 110% !important;
    margin-right: 70%;
  }
}
.mobile5 {
  width: 90%;
  margin-top: 8%;
  margin-right: 0%;
  z-index: 10;

  @include media(">=LGdesktop") {
    width: 100%;
  }

  &:hover {
    width: 110% !important;
    margin-top: -128%;
    margin-right: 85%;
  }
}

.m1Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .mobile1:hover ~ .detailParent {
    opacity: 1;
    .detail {
      &:nth-child(1) {
        transform: translateX(114%);

        // @include media("<=lphone") {
        //   transform: translateX(88%);
        // }

        @include media("<=tablet") {
          transform: translateX(88%);
        }

        @include media("<=desktop", ">tablet") {
          transform: translateX(98%);
        }
      }
      &:nth-child(2) {
        transform: translate(114%, -38%);

        @include media("<=tablet") {
          transform: translate(88%, -38%);
        }

        @include media("<=desktop", ">tablet") {
          transform: translate(98%, -38%);
        }
      }
      &:nth-child(3) {
        transform: translate(114%, -70%);

        @include media("<=tablet") {
          transform: translate(88%, -70%);
        }

        @include media("<=desktop", ">tablet") {
          transform: translate(98%, -70%);
        }
      }
    }
  }

  .detailParent {
    color: white;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    // transform: translateX(14%);
    z-index: 29;
  }
  .detail {
    background-repeat: no-repeat;
    background-size: 185px 115px;
    padding: 0;
    width: 205px;
    height: 90px;

    &:nth-child(1) {
      transform: translateX(70%);
    }
    &:nth-child(2) {
      transform: translate(50%, -38%);
    }
    &:nth-child(3) {
      transform: translate(30%, -70%);
    }
  }
}

.m2Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .mobile2:hover ~ .detailParent {
    opacity: 1;
    .detail {
      &:nth-child(1) {
        transform: translate(-105%, 0%);

        @include media("<=tablet") {
          transform: translate(-78%, 110%);
        }

        @include media("<=desktop", ">tablet") {
          transform: translate(-78%, 75%);
        }
      }
      &:nth-child(2) {
        transform: translate(-105%, -38%);

        @include media("<=tablet") {
          transform: translate(-78%, 68%);
        }

        @include media("<=desktop", ">tablet") {
          transform: translate(-90%, 35%);
        }
      }
      &:nth-child(3) {
        transform: translate(115%, -70%);

        @include media("<=tablet") {
          transform: translate(88%, -70%);
        }

        @include media("<=desktop", ">tablet") {
          transform: translate(100%, -70%);
        }
      }
      &:nth-child(4) {
        transform: translate(115%, -105%);

        @include media("<=tablet") {
          transform: translate(88%, -105%);
        }

        @include media("<=desktop", ">tablet") {
          transform: translate(100%, -105%);
        }
      }
      &:nth-child(5) {
        transform: translate(115%, -155%);

        @include media("<=tablet") {
          transform: translate(88%, -155%);
        }

        @include media("<=desktop", ">tablet") {
          transform: translate(100%, -155%);
        }
      }
    }
  }

  .detailParent {
    color: white;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    // transform: translateX(14%);
    z-index: 19;
  }
  .detail {
    background-repeat: no-repeat;
    background-size: 185px 115px;
    padding: 0;
    width: 205px;
    height: 90px;

    &:nth-child(1) {
      transform: translate(-50%, 0);
    }
    &:nth-child(2) {
      transform: translate(-20%, -38%);
    }
    &:nth-child(3) {
      transform: translate(30%, -70%);
    }
    &:nth-child(4) {
      transform: translate(10%, -105%);
    }
    &:nth-child(5) {
      transform: translate(0%, -155%);
    }
  }
  .left {
    display: block;
  }
}

.m3Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .mobile3:hover ~ .detailParent {
    opacity: 1;
    .detail {
      &:nth-child(1) {
        transform: translate(-105%, -80%);

        @include media("<=tablet") {
          transform: translate(-80%, 0%);
        }

        @include media("<=desktop", ">tablet") {
          transform: translate(-90%, -50%);
        }
      }
      &:nth-child(2) {
        transform: translate(-105%, -110%);

        @include media("<=tablet") {
          transform: translate(-80%, -40%);
        }

        @include media("<=desktop", ">tablet") {
          transform: translate(-90%, -90%);
        }
      }
      &:nth-child(3) {
        transform: translate(110%, -228%);

        @include media("<=tablet") {
          transform: translate(86%, -190%);
        }

        @include media("<=desktop", ">tablet") {
          transform: translate(98%, -228%);
        }
      }
      &:nth-child(4) {
        transform: translate(110%, -262%);

        @include media("<=tablet") {
          transform: translate(86%, -230%);
        }

        @include media("<=desktop", ">tablet") {
          transform: translate(98%, -262%);
        }
      }
    }
  }

  .detailParent {
    color: white;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    // transform: translateX(14%);
    z-index: 19;
  }
  .detail {
    background-repeat: no-repeat;
    background-size: 185px 115px;
    padding: 0;
    width: 205px;
    height: 90px;

    &:nth-child(1) {
      transform: translate(-50%, 0);
    }
    &:nth-child(2) {
      transform: translate(-20%, -38%);
    }
    &:nth-child(3) {
      transform: translate(30%, -70%);
    }
    &:nth-child(4) {
      transform: translate(10%, -105%);
    }
  }
  .left {
    display: block;
  }
}

.m4Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .mobile4:hover ~ .detailParent {
    opacity: 1;
    .detail {
      &:nth-child(1) {
        transform: translate(-140%, -28%);

        @include media("<=tablet") {
          transform: translate(-100%, 50%);
        }

        @include media("<=desktop", ">tablet") {
          transform: translate(-120%, 12%);
        }
      }
      &:nth-child(2) {
        transform: translate(-140%, -60%);

        @include media("<=tablet") {
          transform: translate(-100%, 8%);
        }

        @include media("<=desktop", ">tablet") {
          transform: translate(-120%, -30%);
        }
      }
      &:nth-child(3) {
        transform: translate(-140%, -85%);

        @include media("<=tablet") {
          transform: translate(-100%, -27%);
        }

        @include media("<=desktop", ">tablet") {
          transform: translate(-120%, -75%);
        }
      }
      &:nth-child(4) {
        transform: translate(-140%, -110%);

        @include media("<=tablet") {
          transform: translate(-100%, -62%);
        }

        @include media("<=desktop", ">tablet") {
          transform: translate(-120%, -100%);
        }
      }
    }
  }

  .detailParent {
    color: white;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    // transform: translateX(14%);
    z-index: 19;
  }
  .detail {
    background-repeat: no-repeat;
    background-size: 185px 115px;
    padding: 0;
    width: 205px;
    height: 90px;

    &:nth-child(1) {
      transform: translate(-50%, 0);
    }
    &:nth-child(2) {
      transform: translate(-20%, -38%);
    }
    &:nth-child(3) {
      transform: translate(30%, -70%);
    }
    &:nth-child(4) {
      transform: translate(10%, -105%);
    }
  }
  .left {
    display: block;
  }
}

.m5Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .mobile5:hover ~ .detailParent {
    opacity: 1;
    .detail {
      &:nth-child(1) {
        transform: translate(-148%, -178%);

        @include media("<=tablet") {
          transform: translate(-108%, -50%);
        }

        @include media("<=desktop", ">tablet") {
          transform: translate(-128%, -115%);
        }
      }
      &:nth-child(2) {
        transform: translate(-148%, -210%);

        @include media("<=tablet") {
          transform: translate(-108%, -92%);
        }

        @include media("<=desktop", ">tablet") {
          transform: translate(-128%, -145%);
        }
      }
      &:nth-child(3) {
        transform: translate(-148%, -235%);

        @include media("<=tablet") {
          transform: translate(-108%, -137%);
        }

        @include media("<=desktop", ">tablet") {
          transform: translate(-128%, -170%);
        }
      }
      &:nth-child(4) {
        transform: translate(-148%, -260%);

        @include media("<=tablet") {
          transform: translate(-108%, -172%);
        }

        @include media("<=desktop", ">tablet") {
          transform: translate(-128%, -195%);
        }
      }
    }
  }

  .detailParent {
    color: white;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    // transform: translateX(14%);
    z-index: 19;
  }
  .detail {
    background-repeat: no-repeat;
    background-size: 185px 115px;
    padding: 0;
    width: 205px;
    height: 90px;

    &:nth-child(1) {
      transform: translate(-50%, 0);
    }
    &:nth-child(2) {
      transform: translate(-20%, -38%);
    }
    &:nth-child(3) {
      transform: translate(30%, -70%);
    }
    &:nth-child(4) {
      transform: translate(10%, -105%);
    }
  }
  .left {
    display: block;
  }
}
