@font-face {
  font-family: "Poppins";
  font-weight: 200;
  src: url("assets/fonts/Poppins-Thin.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 300;
  src: url("assets/fonts/Poppins-ExtraLight.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  src: url("assets/fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: url("assets/fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src: url("assets/fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: url("assets/fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 800;
  src: url("assets/fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 900;
  src: url("assets/fonts/Poppins-ExtraBold.ttf");
}

@font-face {
  font-family: "Poppins";
  font-weight: 1000;
  src: url("assets/fonts/Poppins-Black.ttf");
}

#root {
  font-family: "Poppins";
  user-select: none;

  position: absolute;
  width: 100%;
  height: 100%;
  /* overflow: scroll; */
}
body {
  position: fixed;
  height: 100%;
  width: 100%;
}

*,
*::before,
*::after {
  transition: all 400ms ease;
}
